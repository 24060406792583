import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

const LottieAnimation = ({
  className = '',
  data,
  lazyData,
  play = true,
  animationBackground = '',
  ...props
}) => {
  const [animationData, setAnimationData] = useState(data || null);

  useEffect(() => {
    if (lazyData?.length && !animationData) {
      import(`../../assets/animations/${lazyData}.json`).then(setAnimationData);
    }
  }, []);

  return (
    <div
      className={`pointer-events-none w-full h-full ${className}`}
      style={{ backgroundImage: animationBackground }}
    >
      {animationData && (
        <Lottie
          height={'100%'}
          width={'100%'}
          animationData={animationData}
          play={play}
          {...props}
        />
      )}
    </div>
  );
};

export default LottieAnimation;
