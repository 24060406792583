import { Link } from 'gatsby';
import React from 'react';
import ArrowRight from './ArrowRight';

interface CtaLinkProps {
  to: string;
  label: string;
  color?: string;
  reversed?: boolean;
  className?: string;
  divOnly?: boolean;
}

const CtaLink: React.FC<CtaLinkProps> = ({
  to,
  label,
  color = 'text-primary-red hover:text-black focus:text-black',
  reversed,
  className,
  divOnly,
}) => {
  const isExternal = ['http:', 'https:', 'mailto:', 'tel:'].some(
    (prefix) => !!~to?.indexOf(prefix),
  );
  const shouldOpenNewTab = ['http:', 'https:'].some(
    (prefix) => !!~to?.indexOf(prefix),
  );

  const linkContent = (
    <>
      <span className={reversed ? 'ml-3' : 'mr-3'}>{label}</span>
      <ArrowRight
        className={`flex-shrink-0 h-2.5 w-auto transform ${
          reversed && 'rotate-180'
        }`}
      />
    </>
  );

  return divOnly ? (
    <div
      className={`flex items-center text-sm font-semibold transition ${color} ${
        reversed && 'flex-row-reverse'
      }`}
    >
      {linkContent}
    </div>
  ) : isExternal ? (
    <a
      href={to}
      className={`flex items-center text-sm font-semibold transition ${color} ${
        reversed && 'flex-row-reverse'
      }`}
      target={shouldOpenNewTab ? '_blank' : undefined}
    >
      {linkContent}
    </a>
  ) : (
    <Link
      to={to}
      className={`flex items-center text-sm font-semibold transition ${color} ${
        reversed && 'flex-row-reverse'
      }`}
    >
      {linkContent}
    </Link>
  );
};

export default CtaLink;
